
            @import 'src/styles/mixins.scss';
        
@import "@styles/sassVariables";

.desc {
    color: #666;
}
.icon {
    // width: 80px;
    height: 60px;
    @media screen and (max-width: 423px) {
        height: 40px;
    }
}
.lnkTitle {
    color: #000;
}
.region {
    font-size: 11px;
    color: #999;
    margin: 0 auto 10px;
    width: 80% !important;
}
.serviceBlocks {
    max-width: 600px;
    flex-wrap: wrap;
    justify-content: center;
    padding-bottom: 20px;
    margin-left: auto;
    margin-right: auto;
}

.mainBanner {
    height: auto;
    display: flex;
    justify-content: center;
    position: relative;
    align-items: flex-start;
    min-height: calc(100vh - 54px);
    @media screen and (max-width: 730px) {
        padding: 0 20px;
    }

    &::before {
        content: "";
        left: 50%;
        width: 100vw;
        transform: translateX(-50%);
        height: 100%;
        position: absolute;
        background-image: url("/assets/images/background2.png");
        background-size: cover;
        background-repeat: no-repeat;
        background-position: right;
        z-index: 2;
        @media screen and (max-width: 414px) {
            background-image: url("/assets/images/background2.png");
            background-size: cover;
            background-color: #c4c4c4;
            // background-repeat: no-repeat;
            background-position: bottom center;
        }
    }
}
.errorMessage {
    color: $error;
    font-size: 12px;
    text-align: center;
    padding-bottom: 20px;
    margin-top: -20px;
}
.form {
    max-width: 520px;
    margin: 0 auto;
    padding-bottom: 10px;
    z-index: 5;
    width: 100%;
    min-height: 450px;
    display: flex;
    flex-direction: column;

    @media screen and (max-width: 500px) {
        width: 100%;
        box-sizing: border-box;
        padding-left: 20px;
        padding-right: 20px;
    }
}
.form-step-0 {
    min-height: 327px;
}
.formWrapper {
    margin-bottom: 50px;
    margin-top: 50px;
    z-index: 5;
    width: 100%;
    padding: 30px 24px;
    max-width: 700px;
    border-radius: 10px;
    min-height: 500px;
    background-color: white;
    display: flex;
    flex-direction: column;
    position: relative;
    box-shadow: 0px 1px 10px 0px rgb(0 0 0 / 15%);
    overflow: hidden;
    @include md {
        min-width: 400px;
        padding: 40px 40px;
    }
}
.mainTitle {
    font-size: 20px;
    line-height: 1.4;
    text-align: center;
    font-weight: 700;
    box-sizing: border-box;
    width: 100%;
    padding-right: 20px;
    padding-left: 20px;
    margin: 0 auto 20px;
    @media screen and (max-width: 800px) {
        max-width: 560px;
        margin-bottom: 30px;
    }
}
.form-button {
    background-color: $injurycompensation-default-button-color !important;
    color: #fff !important;
    min-height: 50px !important;
    font-size: 16px;
    &:hover {
        background-color: $injurycompensation-default-button-hover-color !important;
    }
}
.form-field {
    max-width: unset;
    margin: 0 auto;
    margin-bottom: 0.5rem;
}
.form-field input[type="text"],
.form-field input[type="tel"],
.form-field input[type="email"],
.form-field input[type="number"] {
    font-family: "Roboto", sans-serif;
    min-height: 50px !important;
    font-size: 16px;
}
.form-field select {
    @media screen and (max-width: 767px) {
        min-height: 45px !important;
    }
}
.back-button {
    padding: 5px 15px;
    border-radius: 5px;
    left: 0px;
    bottom: 10px;
    @media screen and (max-width: 630px) {
        left: 5px;
    }
}
.selling-point {
    font-size: 11px;
    color: #999;
    padding-top: 10px;
}

.bestLawyer {
    font-size: 11px;
    color: #999;
}

.steps-inner {
    flex-grow: 1;
}
.fields-wrapper {
    margin-top: 1.5rem;
}

.step-block {
    select,
    input {
        width: 100% !important;
    }
}

.step-title {
    text-align: center;
    font-size: 20px !important;
    font-weight: 700 !important;
    margin-bottom: 10px;
    @media screen and (max-width: 767px) {
        font-size: 16px !important;
    }
}
.label-as-step-title {
    color: #000;
    text-align: center;
    font-size: 20px !important;
    font-weight: 700 !important;
    margin-bottom: 10px;
    margin-top: -10px;
    margin-bottom: 15px !important;
}
.step-description {
    text-align: center;
    font-weight: 500;
    margin-bottom: 2rem;
    font-size: 14px;
    @include lg {
        font-size: 18px;
    }
}

.outsideTitle {
    // font-size: 14px;
    // @include lg {
    //     font-size: 18px;
    // }
}
.outsideBrief {
    color: #000;
    max-width: 565px;
}

.form-field input[type="date"] {
    min-width: 94%;
}
.submit-button {
    position: relative;
    overflow: hidden;
    &::before {
        content: "";
        background-color: rgba(255, 255, 255, 0.2);
        height: 100%;
        width: 3em;
        display: block;
        position: absolute;
        top: 0;
        left: -4.5em;
        transform: skewX(-45deg) translateX(-130%);
        transition: none;
        animation: blink 2.4s ease infinite;
    }
}

.custom-progress {
    div:nth-child(2) {
        span {
            background-color: $lawyer-consultation-progress !important;
        }
    }
    div {
        &:first-child {
            color: $lawyer-consultation-progress !important;
            background-color: white !important;
            border: 1px solid $lawyer-consultation-progress !important;
            span {
                &:last-child {
                    border-bottom-color: $lawyer-consultation-progress !important;
                }
            }
        }
    }
}

@keyframes blink {
    0% {
        transform: skewX(-45deg) translateX(-130%);
    }

    100% {
        transform: skewX(-45deg) translateX(40rem);
    }
}
.step-block {
    display: flex;
    flex-direction: column;
    justify-content: center;
    min-height: 345px;
}
.mainBannerWrapper {
    width: 100%;
    max-width: 700px;
}
.formStepsWrapper {
    display: flex;
    will-change: transform;
    transform: translate(0%, 0px);
    transition: transform 0.2s ease;
    height: auto; // internet explorer fallback
    height: fit-content;
    // overflow: hidden;
    // padding-top: 30px;
    justify-content: center;
    // padding-bottom: 30px;

    .stepBlock {
        width: 100%;
        flex-shrink: 0;
        position: relative;
        z-index: 50;
        height: auto; // internet explorer fallback
        height: fit-content;
        padding: 0 2px;
        flex-direction: column;
        justify-content: center;
        min-height: 345px;
        .title {
            font-size: 20px;
            font-weight: bold;
            padding-top: 20px !important;
        }
        .brief {
            max-width: 500px;
            margin: 0 auto 50px;
        }

        & > .buttons {
            width: 100%;
        }
    }
}

.formStepsWrapperWindow {
    display: flex;
    will-change: transform;
    transform: translate(0%, 0px);
    transition: transform 0.2s ease;
    height: auto; // internet explorer fallback
    height: fit-content;
    width: 100%;
    .stepBlock {
        width: 100%;
        flex-shrink: 0;
        position: relative;
        z-index: 50;
        height: auto; // internet explorer fallback
        height: fit-content;
        padding: 0 2px;
        flex-direction: column;
        justify-content: center;
        min-height: 345px;
        .title {
            font-size: 20px;
            font-weight: bold;
        }
        .description {
            margin: 10px 0;
        }

        .buttons {
            width: 100%;
        }
    }
}

.progress-bar {
    margin: 70px auto 20px !important;
    // width: 90% !important;
}

.caret {
    svg {
        polyline {
            stroke: #767676;
            @include md {
                stroke: black;
            }
        }
    }
}
